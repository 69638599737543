<template>
  <section class="m-after-sales m-scroll">
    <!-- 头部导航 -->
    <m-header title="选择售后商品"> </m-header>

    <!-- 订单详情 -->
    <div class="order">
      
      <div class="merchant">
        <img src="@/assets/public/icon_dianpu@2x.png" alt="store">
        <span>{{orderDetail.shops_title}}</span>
      </div>
      <van-checkbox-group v-model="ids"  @change="handleChange()" ref="checkbox">
          <van-checkbox v-for="(it,index) in orderDetail.order_goods" @click="itemChange(it,index)" :key="index" :name="it.rec_id+':'+it.goods_number" checked-color="#ED2A24" class="item-check"
          >
            <div class="item-wrap">
              <div class="avatar"><img :src="it.goods_sku_pic_url" alt="img"></div>
              <div class="info van-hairline--bottom">
                <div class="title">
                  <p class="van-ellipsis">{{it.goods_name}}</p>
                  <span>x{{it.goods_number - it.after_sale_number}}</span>
                </div>
                <span>{{it.sku_name}}</span>
                <p>{{orderDetail.order_logistics.country == 507 ? 'US$':'C$'}}{{it.goods_price}}</p>
              </div>
            </div>
            <div  class="goods-number">
              <span>申请数量</span>
              <van-stepper  @change="changeNumber(it,number[index])" @click.native.stop  v-model="number[index]" :default-value="it.goods_number - it.after_sale_number" :max="it.goods_number - it.after_sale_number" />
            </div>
          </van-checkbox>
      </van-checkbox-group>
    </div>

    <!-- 底部全选  -->
    <div v-show="true" class="fixed-bar m-padding">
        <van-checkbox icon-size="" v-model="checkedAll"  @click="handleCheckAll" checked-color="#ED2A24">全选</van-checkbox>
        <span @click="handleSubmit">申请售后</span>
    </div>
  </section>
</template>

<script>
import MHeader  from '@/components/zh/m-header.vue'
import { getOrderDetail, applyFor } from '@/api/zh/order.js'
export default {
  name:'AfterSaleSelect',
  components:{ MHeader },
  data(){
    return {
      checked: false,
      checkedAll: false,
      orderDetail: {order_logistics:''},
      ids: [],
      number: [],
    }
  },
  methods:{
    // 获取订单详情
    getOrder() {
      // 获取订单详情
      getOrderDetail({id: this.$route.params.id}).then(res => {
        if(res) {
          this.orderDetail = res.data
          this.orderDetail.order_goods.forEach(e => {
            this.$set(e, 'checked', false)
            this.$set(e, 'disabled', false)
          })
        }
      }).finally( () => {
         this.$store.state.load = false
      })
    },
    // 选择框 发生改变
    handleChange() {
      let len = this.orderDetail.order_goods.length
      if(this.ids.length == len) {
        this.checkedAll = true
      }else{
        this.checkedAll = false
      }
    },
    // 点击全选
    handleCheckAll(){
      if(this.checkedAll){
        this.orderDetail.order_goods.forEach(el=>{
           this.$set(el, 'disabled', el.goods_number - el.after_sale_number)
        })
         this.$refs.checkbox.toggleAll(true);
      }else{
        this.orderDetail.order_goods.forEach(el=>{
           this.$set(el, 'disabled', false)
        })
        this.$refs.checkbox.toggleAll();
      }
    },
    changeNumber(item,number) {
      this.$set(item, 'disabled', number)
    },
    itemChange(item,index) {
         this.$set(item, 'disabled', this.number[index])
    },
    // 点击提交
    handleSubmit(){
      if (!this.ids.length) {
        return false
      }
      let object = {  }
      this.orderDetail.order_goods.forEach(el=>{
          this.ids.forEach((e,index)=>{
            if (el.rec_id == e.split(":")[0]) {
              object[e.split(":")[0]] = el.disabled ? el.disabled : 1
              this.ids[index] = e.split(":")[0] + ":" + (el.disabled ? el.disabled :1)
            }
        })
      })

      // 首先判断是否能够申请
      let params = {
        after_sale_goods_info: object,
        order_id: this.$route.params.id,
      }
      applyFor(params).then(res => {
        if(res) {
           window.sessionStorage.setItem('aftersale', JSON.stringify(this.orderDetail))
           window.sessionStorage.setItem('aftersalePrice', JSON.stringify(res))
           this.$router.push({path:'/zh/aftersale/submit', query: { order:this.$route.params.id, goods: JSON.stringify(this.ids)  }})
        }
      })     
    }
  },
  created() {
    this.$store.state.load = true
    this.getOrder()
  }
}
</script>

<style lang="less" scoped>
@import './select.less';
  .goods-number {
    height: 48px;
    width: 100%;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    span {
      font-size:14px;
      font-weight:500;
      color:rgba(51,51,51,1);
      line-height:20px;
    }
  }
</style>